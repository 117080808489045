.download-btn,
.download-btn:focus,
.download-btn:hover {
    background-color: #E4E4E4;
    border-radius: 10px;
    height: 35px;
    border: none;
    box-shadow: none;
    align-items: center;
    padding: 0px 8px 3px 9px;
}

.upload-btn,
.upload-btn:focus,
.upload-btn:hover {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #E4E4E4;
    color: #636363;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 35px;
    border: none;
    box-shadow: none;
    align-items: center;
}

.add-btn,
.add-btn:focus {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #5EBFD4;
    color: white;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 35px;
    border: none;
    box-shadow: none;
    align-items: center;
}




.medicine-add-btn,
.medicine-add-btn:focus {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #2C2F73;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 35px;
    border: 1px solid #2C2F73;
    background-color: #F2F2F2;
    box-shadow: none;
    align-items: center;
    margin-right: 10px;
}

.medicine-add-btn:hover {
    color: white;
    border: 1px solid #2C2F73;
}

.medicine-add-btn:hover img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(343deg) brightness(106%) contrast(101%);
    transition-delay: 50ms;
}


.table-active-btn {
    width: 60px;
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    background-color: #60cd5e;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: white;
    border: none;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 65px;
    cursor: pointer;
}

.table-inactive-btn {
    width: 115px;
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    background-color: #fe5151;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: white;
    border: none;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    cursor: pointer;
}

.table-assigned-btn {
    width: 60px;
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    color: green;
    border: none;
    box-shadow: 0 0 2px green !important;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 100px;
}

.table-unassigned-btn {
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    color: #ff4d4f;
    border: none;
    box-shadow: 0 0 2px #ff4d4f !important;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 100px;
}

.table-critical-btn {
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    color: #EB5757;
    background-color: #FBCCCC;
    border: none;
    box-shadow: none;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 80px;
}


.table-completed-btn{
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    color: #27AE60;
    background-color: #CCFBF1;
    border: none;
    box-shadow: none;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 100px;
}

.table-critical-popup-btn {
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    color: #EB5757;
    background-color: #FBCCCC;
    border: none;
    box-shadow: none;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 80px;
    margin-top: 15px;
}


.table-completed-popup-btn{
    height: 28px;
    padding: 6px;
    border-radius: 50px;
    color: #27AE60;
    background-color: #CCFBF1;
    border: none;
    box-shadow: none;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 100px;
    margin-top: 15px;
}

.table-critical-popup-btns {
    height: 30px;
    padding: 6px;
    border-radius: 50px;
    color: #EB5757;
    background-color: #FBCCCC;
    border: none;
    box-shadow: none;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 130px;
}


.table-completed-popup-btns{
    height: 30px;
    padding: 6px;
    border-radius: 50px;
    color: #27AE60;
    background-color: #CCFBF1;
    border: none;
    box-shadow: none;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 80px;
}


.save-btn,
.save-btn:focus {
    background-color: #5EBFD4;
    color: white;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 35px;
    width: 90px;
    border: none;
    box-shadow: none;
}

.cancel-btn,
.cancel-btn:focus,
.cancel-btn:hover {
    background-color: rgb(164, 163, 163);
    color: white;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 35px;
    width: 90px;
    border: none;
    box-shadow: none;
}

.modal-close-btn,
.modal-close-btn:hover,
.modal-close-btn:focus {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #e2e5ffb2;
    color: black;
    border: none;
    box-shadow: none;
    height: 40px;
    width: 40px;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    border-radius: 70%;
    padding-left: 13px;
}

.popup-cancel-btn,
.popup-cancel-btn:hover,
.popup-cancel-btn:focus {
    background-color: white;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border: 1.6px solid #2C2F73;
    border-radius: 8px;
    color: #2C2F73;
    width: 100px;
    height: 38px;
}

.popup-save-btn,
.popup-save-btn:focus {
    background-color: #5EBFD4;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border: none;
    border-radius: 8px;
    color: white;
    width: 100px;
    height: 38px;
}

.select-all-btn,
.select-all-btn:hover,
.select-all-btn:focus {
    background-color: green;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    border: 1.6px solid green;
    border-radius: 8px;
    color: white;
    ;
    display: flex;
    align-items: center;
    gap: 8px;
}

.deSelect-all-btn,
.deSelect-all-btn:hover,
.deSelect-all-btn:focus {
    background-color: red;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    border: 1.6px solid red;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
}


.table-checkOut-btn {
    height: 28px;
    padding: 6px;
    border-radius: 6px;
    color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: red;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.005em;
    width: 80px;
    cursor: pointer;
}

.add-btns,
.add-btns:focus,
.add-btns:hover {
    background-color: #5EBFD4 !important;
    color: white;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 38px;
    width: 100%;
    border: none;
    box-shadow: none;
    align-items: center;
    margin-top: 30px;
}

.remove-btn,
.remove-btns:focus,
.remove-btns:hover {
    background-color: #EB5757 !important;
    color: white;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 38px;
    border: none;
    box-shadow: none;
    align-items: center;
    margin-top: 30px;
}


.ok-btns,
.ok-btns:focus,
.ok-btns:hover {
    background-color: #5EBFD4 !important;
    color: white;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border-radius: 10px;
    height: 38px;
    border: none;
    box-shadow: none;
    align-items: center;
    margin-top: 10px;
    width: 150px;
}