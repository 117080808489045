/* config header start */

.first-aid-kit {
    display: flex;
    position: sticky !important;
    overflow: hidden;
    overflow-x: auto;
    top: 0px !important;
}

.header-top {
    display: flex;
    flex-direction: row;
}

.ant-tooltip {
    min-width: auto !important;
    max-width: 30% !important;
}

.ant-tooltip-inner {
    color: black !important;
    border-radius: 8px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    letter-spacing: 0em !important;
    text-align: justify !important;
}

.ant-tooltip-placement-top {
    padding-bottom: 0px !important;
}

.download-btn_change,
.download-btn_change:focus,
.download-btn_change:hover {
    align-items: center;
    background-color: #5ebfd4;
    border: none;
    border-radius: 10px;
    /* border-radius: 6px; */
    color: #fff;
    display: flex;
    height: 37px;
    margin-left: 10px;
    font-family: "Manrope";
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
}

.download-btn_change img {
    margin-right: 5px;
}

.app-down-button {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C4652;
    padding: 8px 15px;
    margin-left: 20px;
    border: 1px solid #D3D3E1;
    border-radius: 10px;
    white-space: nowrap;
}

.first-aid-kit a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #3C4652;
    padding: 8px 15px;
    border-left: 1px solid #D3D3E1;
    border-top: 1px solid #D3D3E1;
    border-bottom: 1px solid #D3D3E1;
    white-space: nowrap;
}

.app-down-button:hover {
    background-color: #2C2F73;
    border-left: 1px solid #2C2F73;
    border-top: 1px solid #2C2F73;
    border-bottom: 1px solid #2C2F73;
    color: white;
}

.first-aid-kit a:hover {
    background-color: #2C2F73;
    border-left: 1px solid #2C2F73;
    border-top: 1px solid #2C2F73;
    border-bottom: 1px solid #2C2F73;
    color: white;
}

.app-down-button:hover img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(343deg) brightness(106%) contrast(101%);
}

.first-aid-kit a:hover img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(343deg) brightness(106%) contrast(101%);
}

.first-aid-kit a:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.first-aid-kit a:last-child {
    border-right: 1px solid #D3D3E1;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.first-aid-kit .active {
    background-color: #2C2F73;
    border: 1px solid #2C2F73;
    color: white;
    font-weight: 600;
}

.first-aid-kit .active img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(343deg) brightness(106%) contrast(101%);
}


/* config header end */

.first-aid-kit-main {
    max-height: 100vh;
    width: 100%;
    overflow-y: auto;
    margin-top: 5px;
}

.red {
    color: #ff4d4f !important;
}

.first-aid-kit-main .first-aid-kit-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    flex-wrap: wrap;
}

.first-aid-kit-main .first-aid-kit-title h2 {
    font-family: "Inter";
    font-size: 21px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
    padding: 0px 0px 5px 0px;
}

.first-aid-kit-title .filter .ant-input-affix-wrapper {
    font-family: "Inter" !important;
    font-size: 13px;
    width: 289px;
    height: 35px;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    background-color: transparent;
    border: 1px solid #D3D3E1;
    box-shadow: none;
}

.first-aid-kit-title .filter-main {
    display: flex;
    /* align-items: center; */
}

.first-aid-kit-title .filter {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
}

.first-aid-kit-title .filter .ant-input {
    background-color: transparent;
}

.first-aid-kit-title .filter .ant-input::placeholder {
    color: #575B80;
}




.first-aid-kit-main .config-mail-id {
    display: flex;
    margin-top: 40px;
    gap: 150px;
}

.first-aid-kit-main .config-mail-id .first-column {
    width: 50%;
}


.first-aid-kit-main .config-mail-id .mail-table {
    width: 40%;
    margin-top: 0px;
}


.first-aid-kit-main .config-mail-id .key-personal {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.first-aid-kit-main .config-mail-id textarea.ant-input {
    font-size: 12px;
    margin-left: 2px;
    border: none;
    background-color: transparent;
}

.first-aid-kit-main .config-mail-id .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

.first-aid-kit-main .config-mail-id .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.first-aid-kit-main .config-mail-id .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.first-aid-kit-main .config-mail-id .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    background-color: transparent !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, #ff4d4f 0px 0px 0px 1px;
    font-family: "Inter" !important;
    font-size: 12px !important;
    border: none;
    margin: 2px !important;
}

.thales-form .switch-container {
    /* position: relative;  */
    top: 8px;
    white-space: nowrap;
    /* text-align: right; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.thales-form .add-remove-btn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.thales-form .switch-span {
    font-family: "Source Sans Pro" !important;
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
    color: #09101D;
}

.department-model {
    margin-top: 0px;
}

.team-modal {
    margin-top: 0px;
}

.employeeCardId-model {
    margin-top: 0px;
}

.first-aid-kit-main .notification-form {
    margin-top: 20px;
    margin-left: 5px;
}

.first-aid-kit-main .notification-form .icons-size {
    width: 20px;
}

.first-aid-kit-main .notification-form .notifi-btn {
    text-align: right !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.first-aid-kit-main .notification-form .ant-select {
    width: 100% !important;
}

.first-aid-kit-main .notification-form .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.first-aid-kit-main .notification-form .ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
    font-family: "Inter" !important;
    font-size: 13px;
    height: 38px;
    padding: 4px 8px;
    border-radius: 6px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.first-aid-kit-main .notification-form .ant-form-item-label>label {
    font-family: "Inter" !important;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
}

.first-aid-kit-main .notification-form .available-color {
    height: 200px;
    margin-top: 10px;
    background-color: transparent;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
    padding-top: 3px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    ;
}

.first-aid-kit-main .notification-form .available-color ul {
    margin-bottom: 5px;
    padding: 3px 10px;
    cursor: pointer;
}

.first-aid-kit-main .notification-form .colorId-container {
    height: 200px;
    width: 100%;
    margin-top: 10px;
    background-color: transparent;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
    padding-top: 3px;
    text-align: left;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    ;
}

.first-aid-kit-main .notification-form .colorId-container ul {
    margin-bottom: 5px;
    padding: 3px 10px;
    cursor: pointer;
}


.first-aid-kit-main .notification-form .transfer-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}


@media only screen and (max-width: 379px) {
    .first-aid-kit-title .filter .ant-input-affix-wrapper {
        width: 125px;
    }

    .first-aid-kit-main .config-mail-id {
        display: flex;
        margin-top: 40px;
        gap: 15px;
    }

    .first-aid-kit-title .filter-main {
        width: 100%;
        justify-content: flex-end;
    }

    .thales-form .switch-container {
        padding: 2px 0px 10px 0px;
    }
}

@media only screen and (min-width: 380px) and (max-width: 576px) {
    .first-aid-kit-title .filter .ant-input-affix-wrapper {
        width: 150px;
    }

    .first-aid-kit-main .config-mail-id {
        display: flex;
        margin-top: 40px;
        gap: 44px;
    }

    .first-aid-kit-title .filter-main {
        width: 100%;
        justify-content: flex-end;
    }

    .thales-form .switch-container {
        padding: 2px 0px 10px 0px;
    }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .first-aid-kit-main .config-mail-id {
        display: flex;
        margin-top: 40px;
        gap: 65px;
    }

    .thales-form .switch-container {
        padding: 2px 0px 10px 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .first-aid-kit-main .config-mail-id {
        display: flex;
        margin-top: 40px;
        gap: 115px;
    }

    .first-aid-kit-title .filter-main {
        display: flex;
        align-items: center;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .first-aid-kit-title .filter .ant-input-affix-wrapper {
        width: 225px;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {}

@media only screen and (min-width: 1401px) {}

/* Dashboard Slider start*/
.dashboard-slide {
    margin-top: 10px;
}

.dashboard-slide .dashboard-slide-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-slide h2 {
    font-family: "Manrope";
    font-size: 40px;
    color: white;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.dashboard-slide p {
    font-family: "Manrope";
    font-size: 18px;
    color: white;
    font-weight: 500;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
}

.dashboard-detail-cards {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
}

.dashboard-slide .dashboard-detail-cards a {
    width: 30%;
}

.dashboard-emergency-rollcall,
.dashboard-not-in-office,
.dashboard-now-in-office {
    display: flex;
    max-height: 150px;
    width: 100%;
    flex-direction: column;
    padding: 10px 20px;
    border-radius: 8px;
    justify-content: space-between;
}

.dashboard-emergency-rollcall img,
.dashboard-not-in-office img,
.dashboard-now-in-office img {
    position: relative;
    bottom: 10px;
}

.dashboard-tiles h1 {
    font-family: "Manrope";
    font-size: 18px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;
}

.dashboard-emergency-rollcall {
    background-color: #5EBFD4;
}

.dashboard-now-in-office {
    background-color: #C4A7FF;
}

.dashboard-not-in-office {
    background-color: #4F80FF;
}

.dashboard-filters {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.dashboard-filters .suffix-icon {
    padding-left: 10px !important;
}

.dashboard-filters .ant-picker {
    width: 15%;
    height: 30px;
    margin-right: 10px;
    background-color: transparent;
    font-size: 13px !important;
    font-family: "Manrope";
}

.dashboard-filters .ant-picker-input>input,
.dashboard-filters .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
}

.dashboard-filters .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
}

.dashboard-filters .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
    min-width: 100px;
    max-width: 100px;
    border: none;
}

.dashboard-filters .ant-select {
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.dashboard-filters .ant-select-selector:focus-within {
    border: none !important;
    box-shadow: none !important;
}

.dashboard-slide h6 {
    font-family: "Manrope";
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 24px;
}

.dashboard-slide h4 {
    font-family: "Manrope";
    font-size: 25px;
    font-weight: 500;
    color: white;
    line-height: 24px;
}

.dashboard-slide .ant-btn {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 16px;
    text-align: center;
    font-family: "Manrope";
}

.dashboard-slide .btn-image {
    margin-left: 5px;
}

.dashboard-slide .ant-btn:focus,
.dashboard-slide .ant-btn:hover {
    color: black !important;
}

.dashboard-slide .ant-btn:active>span,
.dashboard-slide .ant-btn:focus>span {
    color: black !important;
}

/* Dashboard Slider end*/

.dashboard-statistics .dashboard-count {
    background-color: #ffffff;
    background-color: #ffffff;
    padding: 15px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 15vh;
    white-space: nowrap;
}

.dashboard-statistics .dashboard-count h5 {
    font-size: 30px;
    font-family: "Manrope";
    font-size: 26px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
}

.dashboard-statistics .dashboard-count p {
    color: #909092;
    font-family: "Manrope";
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin: 0px;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-top: 1px;
}

.download-button {
    display: flex;
    align-items: center;
    padding: 4px 5px;
    padding-right: 15px;
    background-color: #2C2F73;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    outline: none;
    color: white;
    font-size: 15px;
    position: relative;
    overflow: hidden;
}

.download-button .icon {
    background-image: url('../../../../../assets/arrow-down.svg');
    background-repeat: no-repeat;
    background-color: #00bfff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.3s, background-image 0.3s;
}

.download-button:hover .icon {
    background-image: url('../../../../../assets/android-logo.svg');
    background-size: 18px 18px;
}

.playstore_icon {
    width: 110px;
    max-width: 110px;
    height: 36px;
    object-fit: cover;
}

.appstore_icon {
    width: 110px;
    height: 36px;
    object-fit: cover;
    margin-left: 8px;
}

@media only screen and (max-width: 1368px) {
    .dashboard-statistics .dashboard-count h5 {
        font-size: 20px;
        white-space: normal;
    }

    .dashboard-statistics .dashboard-count p,
    .dashboard-slide p {
        font-size: 12px;
        white-space: normal;
    }

    .dashboard-slide h2 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 992px) {
    .dashboard-detail-cards {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 20px;
    }

    .dashboard-statistics .dashboard-count p,
    .dashboard-slide p {
        font-size: 14px;
        white-space: normal;
    }

    .dashboard-statistics .dashboard-count {
        min-height: 10vh;
    }

    .dashboard-filters .ant-picker {
        width: 20%;
    }

    @media screen and (max-width: 600px) {
        .dashboard-slide h4 {
            font-size: 23px;
        }

        .dashboard {
            display: block;
        }

        .dashboard-detail-cards {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 10px;
        }

        .dashboard-emergency-rollcall,
        .dashboard-not-in-office,
        .dashboard-now-in-office {
            width: 100%;
        }

        .dashboard-slide {
            padding: 10px;
            border-radius: 10px;
        }

        .dashboard-slide-title {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .dashboard-statistics .dashboard-count p,
        .dashboard-slide p {
            font-size: 16px;
        }

        .dashboard-filters .ant-picker {
            width: 50%;
        }

        .dashboard-slide .dashboard-detail-cards a {
            width: 100%;
        }
    }

}

.printable-qr {
    padding-top: 20px;
    padding-bottom: 20px;
}